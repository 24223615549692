@import url('https://fonts.googleapis.com/css2?family=Anaheim&display=swap');

:root {
    --fontFamily: 'Anaheim', sans-serif;
    --mainColor: #8B5CF6;
    --whiteColor: #ffffff;
    --blackColor: #221638;
    --optionalColor: #9696a0;
    --fontSize: 17px;
    --transition: .5s;
}
body {
    padding: 0;
    margin: 0;
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
.container {
    max-width: 1230px;
}
img {
    max-width: 100%;
    height: auto;
}
:focus {
    outline: 0 !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font: {
        family: var(--fontFamily);
        weight: 800;
    };
}
p {
    color: var(--optionalColor);
    line-height: 1.8;
    margin-bottom: 15px;
    font: {
        weight: 600;
        size: var(--fontSize);
    };
    &:last-child {
        margin-bottom: 0;
    }
}
a {
    color: var(--blackColor);
    text-decoration: none;
    transition: var(--transition);

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.bg-e3fbff {
    background-color: #e3fbff;
}
.bg-f1f8fb {
    background-color: #f1f8fb;
}
.bg-fffbf5 {
    background-color: #fffbf5;
}
.bg-fafafb {
    background-color: #fafafb;
}
.bg-fffaf3 {
    background-color: #fffaf3;
}
/*default-btn*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: var(--whiteColor);
    background-color: var(--mainColor);
    transition: var(--transition);
    border-radius: 5px;
    padding: 12px 25px 12px 50px;
    font: {
        weight: 700;
        size: 16px;
    };
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: var(--blackColor);
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    i {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        font: {
            weight: 700;
            size: 16.5px;
        };
        &.flaticon-user {
            margin-top: -1.5px;
        }
    }
    &:hover {
        color: var(--whiteColor);
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);

        span {
            width: 225%;
            height: 562.5px;
        }
    }
}
/*section-title*/
.section-title {
    text-align: center;
    max-width: 700px;
    margin: {
        left: auto;
        right: auto;
        bottom: 55px;
    };
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: var(--mainColor);
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 0;
        position: relative;
        display: inline-block;
        font: {
            size: 44px;
        };
        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            background-color: var(--blackColor);
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    p {
        display: block;
        max-width: 600px;
        margin: {
            left: auto;
            right: auto;
            top: 12px;
        };
    }
}
/*form-control*/
.form-control {
    height: 50px;
    color: var(--blackColor);
    box-shadow: unset !important;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    transition: var(--transition);
    border-radius: 3px;
    padding: 1px 0 0 15px;
    font: {
        size: 16px;
        weight: 600;
    };
    &::placeholder {
        color: #7e7e7e;
        transition: var(--transition);
    }
    &:focus {
        border-color: var(--mainColor);
        background-color: transparent;

        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    height: auto;
    padding-top: 15px;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    padding: {
        top: 100px;
        bottom: 25px;
    };
    .shape-img1 {
        top: auto;
        bottom: 25%;
        left: 5%;
    }
    &.page-title-style-two {
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }
}
.page-title-content {
    h2 {
        margin-bottom: 0;
        font: {
            size: 44px;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 15px;
        };
        li {
            display: inline-block;
            margin-right: 25px;
            position: relative;
            font: {
                size: 16.5px;
                weight: 700;
            };
            a {
                display: inline-block;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                color: var(--mainColor);
                position: absolute;
                right: -18px;
                top: 5.5px;
                line-height: 1;
                content: "\f104";
                font: {
                    family: Flaticon;
                    size: 11px;
                };
            }
        }
    }
    &.text-center {
        ul {
            li {
                margin: {
                    left: 13px;
                    right: 13px;
                };
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

/*================================================
Shape Img CSS
=================================================*/
.shape-img1 {
    position: absolute;
    left: 10%;
    top: 2%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.shape-img2 {
    position: absolute;
    bottom: 10%;
    z-index: -1;
    right: 30px;
    animation: rotate3d 4s linear infinite;
    opacity: .25;
}
.shape-img3 {
    position: absolute;
    left: 20%;
    top: 5%;
    z-index: -1;
    animation: movebounce 5s linear infinite;
    opacity: .15;
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
@keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -10%, 0);
        transform: translate3d(0, -10%, 0)
    } to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 10%, 0);
        transform: translate3d(0, 10%, 0)
    } to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}
@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-10%, 0, 0);
        transform: translate3d(-10%, 0, 0)
    } to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}
@keyframes fadeInRight {
    0% {
        opacity:0;
        -webkit-transform:translate3d(10%, 0, 0);
        transform: translate3d(10%, 0, 0)
    } to {
        opacity:1;
        -webkit-transform:translateZ(0);
        transform:translateZ(0)
    }
}
.footer-map {
    position: absolute;
    right: 20%;
    top: 40%;
    transform: translateY(-40%);
    z-index: -1;
    opacity: .8;

    img {
        animation: moveleftbounce 5s linear infinite;
    }
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes animeCircleSpin {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(720deg);
    }
    100% {
        transform: rotate(0);
    }
}
.circle-shape1 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}
.shape-img1 {
    position: absolute;
    left: 10%;
    top: 2%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.vector-shape1 {
    position: absolute;
    left: 12%;
    z-index: -1;
    bottom: 40px;

    img {
        animation: {
            name: rotatemetwo;
            duration: 350s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.vector-shape2 {
    position: absolute;
    right: 10%;
    top: 230px;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 350s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@keyframes rotatemetwo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
.vector-shape9 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.vector-shape10 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.vector-shape6 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: .70;

    img {
        animation: movebounce 5s linear infinite;
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.vector-shape6 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: .70;

    img {
        animation: movebounce 5s linear infinite;
    }
}
.vector-shape11 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    img {
        animation: movebounce 5s linear infinite;
    }
}
.map-shape2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 200s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@media only screen and (max-width: 767px) {

    body {
        font-size: 15px;
    }
    p {
        font-size: 15px;
    }
    .container {
        max-width: 100%;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .default-btn {
        padding: 11px 20px 11px 40px;
        font-size: 15px;

        i {
            left: 19px;
            font-size: 14px;
        }
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            bottom: 40px;
        };
        .sub-title {
            margin-bottom: 10px;
            font-size: 14px;
        }
        h2 {
            font-size: 27px;
        }
        p {
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
                top: 10px;
            };
        }
    }
    .form-control {
        height: 45px;
        font-size: 14.5px;
    }
    .lines {
        display: none;
    }
    .vector-shape1, .vector-shape2, .vector-shape3, .vector-shape4, .vector-shape5, .vector-shape6, .vector-shape7, .vector-shape8, .vector-shape9, .vector-shape10, .vector-shape11, .vector-shape12, .circle-shape1, .circle-shape2, .circle-shape3, .circle-shape4, .map-shape1, .map-shape2 {
        display: none !important;
    }
    .divider {
        display: none;
    }
    .footer-map {
        display: none;
    }
    .page-title-area {
        background-position: center center;
        padding: {
            top: 150px;
            bottom: 60px;
        };
        &.page-title-style-two {
            padding: {
                top: 60px;
                bottom: 60px;
            };
        }
    }
    .page-title-content {
        text-align: center;

        h2 {
            font-size: 28px;
        }
        ul {
            margin-top: 12px;

            li {
                font-size: 14.5px;
                margin: {
                    left: 13px;
                    right: 13px;
                };
                &:first-child {
                    margin-left: 0;
                }
                &::before {
                    top: 4.5px;
                    font-size: 10px;
                }
            }
        }
    }
    .shape-img1, .shape-img2, .shape-img3 {
        display: none;
    }
    .pagination-area {
        margin-top: 20px;

        .page-numbers {
            width: 35px;
            height: 35px;
            line-height: 37px;
            font-size: 15px;
        }
    }
    .widget-area {
        padding-left: 0;
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 19px;
            }
        }
        .widget_recent_courses {
            .item {
                .info {
                    span {
                        font-size: 15px;
                    }
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget_popular_products {
            .item {
                .info {
                    span {
                        font-size: 15px;
                    }
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget_insight {
            ul {
                li {
                    a {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget_tracer_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
        &.widget-left-sidebar {
            padding: {
                right: 0;
            };
            padding: {
                top: 0;
                bottom: 40px;
            };
        }
    }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    .container {
        max-width: 540px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    body {
        font-size: 16px;
    }
    p {
        font-size: 16px;
    }
    .container {
        max-width: 720px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .section-title {
        max-width: 600px;
        margin-bottom: 45px;

        .sub-title {
            font-size: 14px;
        }
        h2 {
            font-size: 35px;
        }
        p {
            max-width: 500px;
        }
    }
    .default-btn {
        font-size: 15px;
    }
    .vector-shape1, .vector-shape2, .vector-shape3, .vector-shape4, .vector-shape5, .vector-shape6, .vector-shape7, .vector-shape8, .vector-shape9, .vector-shape10, .vector-shape11, .vector-shape12, .circle-shape1, .circle-shape2, .circle-shape3, .circle-shape4, .map-shape1, .map-shape2 {
        display: none !important;
    }
    .footer-map {
        right: auto;
        left: 50%;
        transform: translateX(-50%) translateY(-40%);
    }
    .vector-shape4, .vector-shape5, .map-shape1 {
        display: none;
    }
    .divider {
        display: none;
    }
    .vector-shape1, .vector-shape2, .vector-shape3 {
        display: none;
    }
    .vector-shape6 {
        display: none;
    }
    .vector-shape11 {
        display: none;
    }.page-title-area {
        background-position: center center;
        padding: {
            bottom: 80px;
            top: 160px;
        };
        &.page-title-style-two {
            padding: {
                top: 80px;
                bottom: 80px;
            };
        }
    }
    .page-title-content {
        text-align: center;

        h2 {
            font: {
                size: 38px;
            };
        }
        ul {
            li {
                margin: {
                    left: 10px;
                    right: 15px;
                };
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .widget-area {
        padding-left: 0;
        margin-top: 35px;

        &.widget-left-sidebar {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 35px;
            };
        }
    }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    body {
        font-size: 16px;
    }
    p {
        font-size: 16px;
    }
    .container {
        max-width: 960px;
    }
    .section-title {
        max-width: 700px;
        margin-bottom: 50px;

        h2 {
            font-size: 36px;
        }
    }
    .default-btn {
        font-size: 15px;
    }
    .footer-map {
        right: auto;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0.55;
    }
    .vector-shape3 {
        display: none !important;
    }
    .vector-shape7 {
        img {
            max-width: 60%;
        }
    }
    .vector-shape8 {
        display: none !important;
    }
    .vector-shape6 {
        display: none !important;
    }
    .circle-shape1, .circle-shape2, .lines {
        display: none !important;
    }
    .vector-shape4, .vector-shape5 {
        display: none !important;
    }
    .divider {
        height: 70px;
    }
    .vector-shape1, .vector-shape2 {
        display: none !important;
    }
    .page-title-area {
        padding: {
            top: 160px;
            bottom: 80px;
        };
        &.page-title-style-two {
            padding: {
                bottom: 90px;
                top: 80px;
            };
        }
    }
    .page-title-content {
        text-align: center;

        h2 {
            font-size: 40px;
        }
        ul {
            li {
                margin: {
                    left: 14px;
                    right: 14px;
                };
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    .widget-area {
        padding-left: 0;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {

    .container {
        max-width: 1140px;
    }
    .circle-shape1 {
        display: none;
    }

}

$disable-background-color: #eceeef;
.container {
    .multiselect-dropdown {
        position: relative;
        width: 100%;
        font-size: inherit;
        font-family: inherit;
        margin-bottom: 15px;

        .dropdown-btn {
            display: inline-block;
            width: 100%;
            padding: 6px 12px;
            font-weight: normal;
            line-height: 1.52857143;
            text-align: left;
            vertical-align: middle;
            cursor: pointer;
            background-image: none;
            border-radius: 4px;
            padding-top: 12px !important;
            padding-bottom: 12px !important;
            background-color: #f5f5f5 !important;
            border: none !important;
            color: #7b7b7b !important;

            .selected-item {
                border: 1px solid var(--mainColor) !important;
                margin-right: 4px;
                background: var(--mainColor) !important;
                padding: 0px 5px;
                color: #fff;
                border-radius: 2px;
                float: left;

                a {
                    text-decoration: none;
                }
            }

            .selected-item:hover {
                box-shadow: 1px 1px #959595;
            }

            .dropdown-down {
                display: inline-block;
                top: 15px;
                width: 0;
                height: 0;
                border-top: 10px solid #adadad;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }

            .dropdown-up {
                display: inline-block;
                width: 0;
                height: 0;
                border-bottom: 10px solid #adadad;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
        }

        .disabled {
            & > span {
                background-color: $disable-background-color;
            }
        }
    }

    .dropdown-list {
        position: absolute;
        padding-top: 6px;
        width: 100%;
        z-index: 9999;
        border: 1px solid #ccc;
        border-radius: 3px;
        background: #fff;
        margin-top: 10px;
        box-shadow: 0px 1px 5px #959595;

        ul {
            padding: 0px;
            list-style: none;
            overflow: auto;
            margin: 0px;
        }

        li {
            padding: 6px 10px;
            cursor: pointer;
            text-align: left;
        }

        .filter-textbox {
            border-bottom: 1px solid #ccc;
            position: relative;
            padding: 10px;

            input {
                border: 0px;
                width: 100%;
                padding: 0px 0px 0px 26px;
            }

            input:focus {
                outline: none;
            }
        }
    }

    .multiselect-item-checkbox input[type='checkbox'] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .multiselect-item-checkbox input[type='checkbox']:focus + div:before,
    .multiselect-item-checkbox input[type='checkbox']:hover + div:before {
        border-color: var(--mainColor) !important;
        background-color: #f2f2f2;
    }

    .multiselect-item-checkbox input[type='checkbox']:active + div:before {
        transition-duration: 0s;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div {
        position: relative;
        padding-left: 2em;
        vertical-align: middle;
        user-select: none;
        cursor: pointer;
        margin: 0px;
        color: #000;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div:before {
        box-sizing: content-box;
        content: '';
        color: var(--mainColor) !important;
        position: absolute;
        top: 50%;
        left: 0;
        width: 14px;
        height: 14px;
        margin-top: -9px;
        border: 2px solid var(--mainColor) !important;
        text-align: center;
        transition: all 0.4s ease;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div:after {
        box-sizing: content-box;
        content: '';
        background-color: var(--mainColor) !important;
        position: absolute;
        top: 50%;
        left: 4px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        transform: scale(0);
        transform-origin: 50%;
        transition: transform 200ms ease-out;
    }

    .multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
        border-color: #cccccc;
    }

    .multiselect-item-checkbox
    input[type='checkbox']:disabled:focus
    + div:before
    .multiselect-item-checkbox
    input[type='checkbox']:disabled:hover
    + div:before {
        background-color: inherit;
    }

    .multiselect-item-checkbox
    input[type='checkbox']:disabled:checked
    + div:before {
        background-color: #cccccc;
    }

    .multiselect-item-checkbox input[type='checkbox'] + div:after {
        background-color: transparent;
        top: 50%;
        left: 4px;
        width: 8px;
        height: 3px;
        margin-top: -4px;
        border-style: solid;
        border-color: #ffffff;
        border-width: 0 0 3px 3px;
        border-image: none;
        transform: rotate(-45deg) scale(0);
    }

    .multiselect-item-checkbox input[type='checkbox']:checked + div:after {
        content: '';
        transform: rotate(-45deg) scale(1);
        transition: transform 200ms ease-out;
    }

    .multiselect-item-checkbox input[type='checkbox']:checked + div:before {
        animation: borderscale 200ms ease-in;
        background: var(--mainColor) !important;
    }

    .multiselect-item-checkbox input[type='checkbox']:checked + div:after {
        transform: rotate(-45deg) scale(1);
    }

    @keyframes borderscale {
        50% {
            box-shadow: 0 0 0 2px var(--mainColor) !important;
        }
    }

    .dropdown-multiselect__caret {
        margin-top: 8px !important;
    }
}
